import React from 'react'
import { colors } from 'components/shared/utils/colors'
import * as S from './styles'

function Modal({
  open,
  title,
  content,
  actions,
  justifyActionsContent,
  justifyTitle,
  entityList,
  onClose,
  xClick,
  maxWidth = 'lg',
  ...other
}: ModalProps): React.FunctionComponentElement<ModalProps> {
  return (
    <div>
      <S.StyledDialog
        open={!!open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
        maxWidth={maxWidth}
        {...other}
      >
        <S.IconContainer>
          <S.StyledIcon name="X" onClick={xClick} color={colors.gray30} size="1.125rem" />
        </S.IconContainer>
        {title && (
          <S.StyledDialogTitle id="alert-dialog-title" justifyTitle={justifyTitle}>
            {title}
          </S.StyledDialogTitle>
        )}
        {content && (
          <S.StyledDialogContent entityList={entityList}>{content}</S.StyledDialogContent>
        )}
        {actions && (
          <S.StyledDialogActions justifyActionsContent={justifyActionsContent}>
            {actions}
          </S.StyledDialogActions>
        )}
      </S.StyledDialog>
    </div>
  )
}

interface ModalProps {
  open?: boolean
  title?: string | React.ReactNode
  content?: React.ReactNode
  actions?: React.ReactNode
  justifyActionsContent?: string
  justifyTitle?: string
  entityList?: boolean
  onClose?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void
  xClick?: React.MouseEventHandler<HTMLElement>
  PaperProps?: { [key: string]: any }
  maxWidth?: ModalMaxWidth
}

type ModalMaxWidth = false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined

Modal.defaultProps = {
  justifyTitle: 'flex-start',
  justifyActionsContent: 'flex-start',
}

export default Modal
export { S as Styles }
