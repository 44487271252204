import { gql } from '@apollo/client'

export const feedbackQuery = `
query GetFeedback(
  $_id: String
  $applicationIDs: [String!]!
  $category: [String!]
  $manualCategory: [String!]
  $includeNullVerbatims: Boolean
  $isFlagged: Boolean
  $isReviewed: Boolean
  $isTrainingDataOnly: Boolean
  $isMobileUser: [Boolean!]
  $limit: Float
  $minVerbatimLength: Float
  $maxVerbatimLength: Float
  $offset: Float
  $page: Float
  $period: String
  $reroutePage: Boolean
  $searchBy: String
  $searchText: String
  $showPII: Boolean
  $sortDirection: String
  $sortField: String
  $subcategory: [String!]
  $appVersion: [String!]
  $surveyID: [String!]
  $isUserSelectedCategory: Boolean
  $source: [String!]
  $includeHidden: Boolean
  $feedbackFields: [String!]
  $CSAT: String
  $starRating: [Float!]
  $starRatingExternal: [Float!]
  $SR: [String!]
  $awsSentimentPositive: String
  $awsSentimentNegative: String
  $userRole: [String!]
  $userType: [String!]
  $billerType: [String!]
  $billingServices: [String!]
  $accountExpired: [String!]
  $autoPayStatus: [String!]
  $loggedInStatus: [String!]
  $footprint: [String!]
  $SCP: [String!]
  $pageName: [String!]
  $subscribedServices: [String!]
  $userSelectedCategories: [String!]
  $accountType: [String!]
  $journeyType: [String!]
  $activationMethod: [String!]
  $activatedExperimentUuids: [String!]
  $experimentUuids: [String!]
  $variantUuids: [String!]
  $middleCategory: [String!]
  $isRerouteSent: Boolean
  $language: [String!]
) {
  getFeedback(
    _id: $_id
    applicationIDs: $applicationIDs
    category: $category
    manualCategory: $manualCategory
    includeNullVerbatims: $includeNullVerbatims
    isFlagged: $isFlagged
    isReviewed: $isReviewed
    isTrainingDataOnly: $isTrainingDataOnly
    isMobileUser: $isMobileUser
    limit: $limit
    maxVerbatimLength: $maxVerbatimLength
    minVerbatimLength: $minVerbatimLength
    offset: $offset
    page: $page
    period: $period
    reroutePage: $reroutePage
    searchBy: $searchBy
    searchText: $searchText
    sortDirection: $sortDirection
    sortField: $sortField
    showPII: $showPII
    subcategory: $subcategory
    appVersion: $appVersion
    surveyID: $surveyID
    isUserSelectedCategory: $isUserSelectedCategory
    source: $source
    includeHidden: $includeHidden
    feedbackFields: $feedbackFields
    CSAT: $CSAT
    starRating: $starRating
    starRatingExternal: $starRatingExternal
    SR: $SR
    awsSentimentPositive: $awsSentimentPositive
    awsSentimentNegative: $awsSentimentNegative
    userRole: $userRole
    userType: $userType
    billerType: $billerType
    billingServices: $billingServices
    accountExpired: $accountExpired
    autoPayStatus: $autoPayStatus
    loggedInStatus: $loggedInStatus
    footprint: $footprint
    SCP: $SCP
    pageName: $pageName
    subscribedServices: $subscribedServices
    userSelectedCategories: $userSelectedCategories
    accountType: $accountType
    activationMethod: $activationMethod
    journeyType: $journeyType
    activatedExperimentUuids: $activatedExperimentUuids
    experimentUuids: $experimentUuids
    variantUuids: $variantUuids
    middleCategory: $middleCategory
    isRerouteSent: $isRerouteSent
    language: $language
  ) {
    _id
    source
    visitId
    timestamp
    appName
    appType
    verbatim
    CSAT
    CES
    NPS
    SR
    starRatingExternal
    starRating
    userSelectedCategory
    surveyID
    appVersion
    footprint
    browser
    os
    deviceManufacturer
    deviceType
    deviceModel
    deviceFormFactor
    loggedInStatus
    awsCategories {
      _id
      category
      subcategory
      description
      parent
      isArchived
      hasArchivedParent
    }
    awsCategoriesScore
    awsSentiment {
      negative
      positive
      mixed
      neutral
    }
    prismAppliedCategories {
      _id
      category
      subcategory
      description
      isArchived
      hasArchivedParent
    }
    prismAppliedManualCategories {
      _id
      category
      manualCategory
      description
      isArchived
    }
    reroutedCategories {
      _id
      category
      subcategory
      description
      isArchived
      hasArchivedParent
    }
    awsKeywords
    useForRetrain
    isFlagged
    isReviewed
    isHidden
    notes
    SCP
    pageName
    accountType
    billerType
    billingServices
    deliveryMethod
    userRole
    autoPayStatus
    expirationDate
    accountExpired
    userType
    internetService
    secureConnectService
    tvService
    voiceService
    wifiService
    mobileService
    encAccountNumber
    isNullVerbatim
    activationProcessScale
    clarityScale
    easeOfEquipmentConnectionScale
    journeyType
    surveyType
    closedLoop
    activationMethod
    visit
    language
    altVerbatim
    businessUnit
    department
    phoneNumber
    email
    firstName
    lastName
    pid
    title
    xumoLogin
    accountNumber
    username
    mlAnalysisField
    recentIssues
    connectivityIssueRecurrence
    wifiServiceIssues
    problemDeviceTypes
    resolutionActions
    speedExpectations
    rebootFrequency
    wifiPodsExperience
    wifiPodsSpeedExpectation
    installationActivationExperience
    fileUploadID
    customerSupportActions
    employeeSupportActions
  }
}
`

export const GET_FEEDBACK = gql`
  ${feedbackQuery}
`

export const GET_FEEDBACK_BY_ID = gql`
  query GetFeedbackByID($_id: String!, $showPII: Boolean) {
    getFeedbackByID(_id: $_id, showPII: $showPII) {
      _id
      source
      visitId
      timestamp
      appName
      appType
      verbatim
      CSAT
      CES
      NPS
      SR
      starRatingExternal
      starRating
      userSelectedCategory
      surveyID
      appVersion
      footprint
      browser
      os
      deviceManufacturer
      deviceType
      deviceModel
      deviceFormFactor
      loggedInStatus
      awsCategories {
        _id
        category
        subcategory
        description
      }
      awsCategoriesScore
      awsSentiment {
        negative
        positive
        mixed
        neutral
      }
      prismAppliedCategories {
        _id
        category
        subcategory
        description
      }
      prismAppliedManualCategories {
        _id
        category
        manualCategory
        description
        isArchived
      }
      reroutedCategories {
        _id
        category
        subcategory
        description
      }
      awsKeywords
      useForRetrain
      isFlagged
      isReviewed
      isHidden
      isMobileUser
      notes
      SCP
      pageName
      accountType
      billerType
      billingServices
      deliveryMethod
      userRole
      autoPayStatus
      expirationDate
      accountExpired
      userType
      internetService
      secureConnectService
      tvService
      voiceService
      wifiService
      mobileService
      encAccountNumber
      isNullVerbatim
      activationProcessScale
      clarityScale
      easeOfEquipmentConnectionScale
      journeyType
      surveyType
      closedLoop
      activationMethod
      language
      altVerbatim
      businessUnit
      department
      phoneNumber
      email
      firstName
      lastName
      pid
      title
      xumoLogin
      accountNumber
      username
      recentIssues
      connectivityIssueRecurrence
      wifiServiceIssues
      problemDeviceTypes
      resolutionActions
      speedExpectations
      rebootFrequency
      wiFiPodsExperience
      wiFiPodsSpeedExpectation  
      installationActivationExperience
      fileUploadID
      customerSupportActions
      employeeSupportActions
    }
  }
`

export const GET_FEEDBACK_CSV = gql`
  query GetFeedbackCSV(
    $_id: String
    $applicationIDs: [String!]!
    $category: [String!]
    $manualCategory: [String!]
    $includeNullVerbatims: Boolean
    $isFlagged: Boolean
    $isReviewed: Boolean
    $isTrainingDataOnly: Boolean
    $isMobileUser: [Boolean!]
    $limit: Float
    $minVerbatimLength: Float
    $maxVerbatimLength: Float
    $offset: Float
    $page: Float
    $period: String
    $reroutePage: Boolean
    $searchBy: String
    $searchText: String
    $showPII: Boolean
    $sortDirection: String
    $sortField: String
    $subcategory: [String!]
    $appVersion: [String!]
    $surveyID: [String!]
    $isUserSelectedCategory: Boolean
    $source: [String!]
    $includeHidden: Boolean
    $totalCount: Float!
    $fileName: String
    $entityName: String
    $feedbackFields: [String!]
    $CSAT: String
    $starRating: [Float!]
    $starRatingExternal: [Float!]
    $SR: [String!]
    $awsSentimentPositive: String
    $awsSentimentNegative: String
    $userType: [String!]
    $journeyType: [String!]
    $pageName: [String!]
    $accountType: [String!]
    $billerType: [String!]
    $billingServices: [String!]
    $userRole: [String!]
    $accountExpired: [String!]
    $autoPayStatus: [String!]
    $footprint: [String!]
    $loggedInStatus: [String!]
    $SCP: [String!]
    $subscribedServices: [String!]
    $activationMethod: [String!]
    $userSelectedCategories: [String!]
    $activatedExperimentUuids: [String!]
    $experimentUuids: [String!]
    $variantUuids: [String!]
    $middleCategory: [String!]
    $language: [String!]
  ) {
    getFeedbackCSV(
      _id: $_id
      applicationIDs: $applicationIDs
      category: $category
      manualCategory: $manualCategory
      includeNullVerbatims: $includeNullVerbatims
      isFlagged: $isFlagged
      isReviewed: $isReviewed
      isTrainingDataOnly: $isTrainingDataOnly
      isMobileUser: $isMobileUser
      limit: $limit
      maxVerbatimLength: $maxVerbatimLength
      minVerbatimLength: $minVerbatimLength
      offset: $offset
      page: $page
      period: $period
      reroutePage: $reroutePage
      searchBy: $searchBy
      searchText: $searchText
      sortDirection: $sortDirection
      sortField: $sortField
      showPII: $showPII
      subcategory: $subcategory
      appVersion: $appVersion
      surveyID: $surveyID
      isUserSelectedCategory: $isUserSelectedCategory
      source: $source
      includeHidden: $includeHidden
      totalCount: $totalCount
      fileName: $fileName
      entityName: $entityName
      feedbackFields: $feedbackFields
      CSAT: $CSAT
      starRating: $starRating
      starRatingExternal: $starRatingExternal
      SR: $SR
      awsSentimentPositive: $awsSentimentPositive
      awsSentimentNegative: $awsSentimentNegative
      userType: $userType
      journeyType: $journeyType
      pageName: $pageName
      accountType: $accountType
      billerType: $billerType
      billingServices: $billingServices
      userRole: $userRole
      accountExpired: $accountExpired
      autoPayStatus: $autoPayStatus
      footprint: $footprint
      loggedInStatus: $loggedInStatus
      SCP: $SCP
      subscribedServices: $subscribedServices
      activationMethod: $activationMethod
      userSelectedCategories: $userSelectedCategories
      activatedExperimentUuids: $activatedExperimentUuids
      experimentUuids: $experimentUuids
      variantUuids: $variantUuids
      middleCategory: $middleCategory
      language: $language
    )
  }
`

export const GET_FEEDBACK_COUNT = gql`
  query GetFeedbackCount(
    $_id: String
    $applicationIDs: [String!]!
    $category: [String!]
    $manualCategory: [String!]
    $includeNullVerbatims: Boolean
    $includeAppfiguresRatings: Boolean
    $isFlagged: Boolean
    $isReviewed: Boolean
    $isTrainingDataOnly: Boolean
    $isMobileUser: [Boolean!]
    $maxVerbatimLength: Float
    $minVerbatimLength: Float
    $period: String
    $reroutePage: Boolean
    $searchBy: String
    $searchText: String
    $subcategory: [String!]
    $appVersion: [String!]
    $surveyID: [String!]
    $isUserSelectedCategory: Boolean
    $source: [String!]
    $includeHidden: Boolean
    $CSAT: String
    $starRating: [Float!]
    $starRatingExternal: [Float!]
    $SR: [String!]
    $awsSentimentPositive: String
    $awsSentimentNegative: String
    $userRole: [String!]
    $userType: [String!]
    $billerType: [String!]
    $billingServices: [String!]
    $accountExpired: [String!]
    $autoPayStatus: [String!]
    $loggedInStatus: [String!]
    $footprint: [String!]
    $SCP: [String!]
    $pageName: [String!]
    $subscribedServices: [String!]
    $userSelectedCategories: [String!]
    $accountType: [String!]
    $journeyType: [String!]
    $activationMethod: [String!]
    $activatedExperimentUuids: [String!]
    $experimentUuids: [String!]
    $variantUuids: [String!]
    $middleCategory: [String!]
    $isRerouteSent: Boolean
    $language: [String!]
  ) {
    getFeedbackCount(
      _id: $_id
      applicationIDs: $applicationIDs
      category: $category
      manualCategory: $manualCategory
      includeNullVerbatims: $includeNullVerbatims
      includeAppfiguresRatings: $includeAppfiguresRatings
      isFlagged: $isFlagged
      isReviewed: $isReviewed
      isTrainingDataOnly: $isTrainingDataOnly
      isMobileUser: $isMobileUser
      maxVerbatimLength: $maxVerbatimLength
      minVerbatimLength: $minVerbatimLength
      period: $period
      reroutePage: $reroutePage
      searchBy: $searchBy
      searchText: $searchText
      subcategory: $subcategory
      appVersion: $appVersion
      surveyID: $surveyID
      isUserSelectedCategory: $isUserSelectedCategory
      source: $source
      includeHidden: $includeHidden
      CSAT: $CSAT
      starRating: $starRating
      starRatingExternal: $starRatingExternal
      SR: $SR
      awsSentimentPositive: $awsSentimentPositive
      awsSentimentNegative: $awsSentimentNegative
      userRole: $userRole
      userType: $userType
      billerType: $billerType
      billingServices: $billingServices
      accountExpired: $accountExpired
      autoPayStatus: $autoPayStatus
      loggedInStatus: $loggedInStatus
      footprint: $footprint
      SCP: $SCP
      pageName: $pageName
      subscribedServices: $subscribedServices
      userSelectedCategories: $userSelectedCategories
      accountType: $accountType
      journeyType: $journeyType
      activationMethod: $activationMethod
      activatedExperimentUuids: $activatedExperimentUuids
      experimentUuids: $experimentUuids
      variantUuids: $variantUuids
      middleCategory: $middleCategory
      isRerouteSent: $isRerouteSent
      language: $language
    )
  }
`

export const GET_FEEDBACK_TRENDS = gql`
  query GetFeedbackTrends(
    $applicationIDs: [String!]!
    $period: String!
    $category: [String!]
    $subcategory: [String!]
    $manualCategory: [String!]
    $showAsRollup: Boolean
    $isReviewed: Boolean
    $appVersion: [String!]
    $surveyID: [String!]
    $isUserSelectedCategory: Boolean
    $source: [String!]
    $includeNullVerbatims: Boolean
    $isMobileUser: [Boolean!]
    $isSubcategoryDetailsPage: Boolean
    $CSAT: String
    $starRating: [Float!]
    $starRatingExternal: [Float!]
    $SR: [String!]
    $awsSentimentPositive: String
    $awsSentimentNegative: String
    $userRole: [String!]
    $userType: [String!]
    $billerType: [String!]
    $billingServices: [String!]
    $accountExpired: [String!]
    $autoPayStatus: [String!]
    $loggedInStatus: [String!]
    $footprint: [String!]
    $SCP: [String!]
    $pageName: [String!]
    $subscribedServices: [String!]
    $userSelectedCategories: [String!]
    $accountType: [String!]
    $journeyType: [String!]
    $activationMethod: [String!]
    $activatedExperimentUuids: [String!]
    $experimentUuids: [String!]
    $variantUuids: [String!]
    $middleCategory: [String!]
    $language: [String!]
  ) {
    getFeedbackTrends(
      applicationIDs: $applicationIDs
      period: $period
      category: $category
      subcategory: $subcategory
      manualCategory: $manualCategory
      showAsRollup: $showAsRollup
      isReviewed: $isReviewed
      appVersion: $appVersion
      surveyID: $surveyID
      isUserSelectedCategory: $isUserSelectedCategory
      isMobileUser: $isMobileUser
      source: $source
      includeNullVerbatims: $includeNullVerbatims
      isSubcategoryDetailsPage: $isSubcategoryDetailsPage
      CSAT: $CSAT
      starRating: $starRating
      starRatingExternal: $starRatingExternal
      SR: $SR
      awsSentimentPositive: $awsSentimentPositive
      awsSentimentNegative: $awsSentimentNegative
      userRole: $userRole
      userType: $userType
      billerType: $billerType
      billingServices: $billingServices
      accountExpired: $accountExpired
      autoPayStatus: $autoPayStatus
      loggedInStatus: $loggedInStatus
      footprint: $footprint
      SCP: $SCP
      pageName: $pageName
      subscribedServices: $subscribedServices
      userSelectedCategories: $userSelectedCategories
      accountType: $accountType
      journeyType: $journeyType
      activationMethod: $activationMethod
      activatedExperimentUuids: $activatedExperimentUuids
      experimentUuids: $experimentUuids
      variantUuids: $variantUuids
      middleCategory: $middleCategory
      language: $language
    ) {
      avgCSAT
      avgCES
      avgNPS
      avgSR
      avgAWSPositive
      avgAWSNegative
      avgAWSMixed
      avgAWSNeutral
      feedbackCount
      internalFeedbackCount
      avgStarRating
      avgStarRatingExternal
      nullVerbatimsCount
      hasAppfiguresNullVerbatims
      nullVerbatimsCountAppfigures
      verbatimsCountAppfigures
      sumCSAT
      countCSAT
      sumSR
      countSR
      countStarRating
      sumStarRating
      sumStarRatingExternal
      countStarRatingExternal
      sumNPS
      countNPS
    }
  }
`

export const GET_EXPERIMENT_UUIDS = gql`
  query GetExperimentUUIDs(
    $_id: String
    $applicationIDs: [String!]!
    $category: [String!]
    $manualCategory: [String!]
    $includeNullVerbatims: Boolean
    $isFlagged: Boolean
    $isReviewed: Boolean
    $isTrainingDataOnly: Boolean
    $isMobileUser: [Boolean!]
    $limit: Float
    $minVerbatimLength: Float
    $maxVerbatimLength: Float
    $offset: Float
    $page: Float
    $period: String
    $reroutePage: Boolean
    $searchBy: String
    $searchText: String
    $showPII: Boolean
    $sortDirection: String
    $sortField: String
    $subcategory: [String!]
    $appVersion: [String!]
    $surveyID: [String!]
    $isUserSelectedCategory: Boolean
    $source: [String!]
    $includeHidden: Boolean
    $feedbackFields: [String!]
    $CSAT: String
    $starRating: [Float!]
    $starRatingExternal: [Float!]
    $SR: [String!]
    $awsSentimentPositive: String
    $awsSentimentNegative: String
    $userRole: [String!]
    $userType: [String!]
    $billerType: [String!]
    $billingServices: [String!]
    $accountExpired: [String!]
    $autoPayStatus: [String!]
    $loggedInStatus: [String!]
    $footprint: [String!]
    $SCP: [String!]
    $pageName: [String!]
    $subscribedServices: [String!]
    $userSelectedCategories: [String!]
    $accountType: [String!]
    $journeyType: [String!]
    $activationMethod: [String!]
    $activatedExperimentUuids: [String!]
    $experimentUuids: [String!]
    $variantUuids: [String!]
    $language: [String!]
  ) {
    getExperimentUUIDs(
      _id: $_id
      applicationIDs: $applicationIDs
      category: $category
      manualCategory: $manualCategory
      includeNullVerbatims: $includeNullVerbatims
      isFlagged: $isFlagged
      isReviewed: $isReviewed
      isTrainingDataOnly: $isTrainingDataOnly
      isMobileUser: $isMobileUser
      limit: $limit
      maxVerbatimLength: $maxVerbatimLength
      minVerbatimLength: $minVerbatimLength
      offset: $offset
      page: $page
      period: $period
      reroutePage: $reroutePage
      searchBy: $searchBy
      searchText: $searchText
      sortDirection: $sortDirection
      sortField: $sortField
      showPII: $showPII
      subcategory: $subcategory
      appVersion: $appVersion
      surveyID: $surveyID
      isUserSelectedCategory: $isUserSelectedCategory
      source: $source
      includeHidden: $includeHidden
      feedbackFields: $feedbackFields
      CSAT: $CSAT
      starRating: $starRating
      starRatingExternal: $starRatingExternal
      SR: $SR
      awsSentimentPositive: $awsSentimentPositive
      awsSentimentNegative: $awsSentimentNegative
      userRole: $userRole
      userType: $userType
      billerType: $billerType
      billingServices: $billingServices
      accountExpired: $accountExpired
      autoPayStatus: $autoPayStatus
      loggedInStatus: $loggedInStatus
      footprint: $footprint
      SCP: $SCP
      pageName: $pageName
      subscribedServices: $subscribedServices
      userSelectedCategories: $userSelectedCategories
      accountType: $accountType
      activationMethod: $activationMethod
      journeyType: $journeyType
      activatedExperimentUuids: $activatedExperimentUuids
      experimentUuids: $experimentUuids
      variantUuids: $variantUuids
      language: $language
    ) {
      variantUuids
      experimentUuids
      activatedExperimentUuids
    }
  }
`

export const GET_OVERALL_FEEDBACK_TRENDS = gql`
  query getOverallTrends($period: String!) {
    getOverallTrends(period: $period) {
      applicationID
      avgCSAT
      avgCES
      avgNPS
      avgSR
      avgStarRating
      avgStarRatingExternal
      avgAWSPositive
      avgAWSNegative
      avgAWSMixed
      avgAWSNeutral
      feedbackCount
    }
  }
`

export const GET_FEEDBACK_OVERVIEW_CATEGORIES = gql`
  query GetFeedbackOverviewCategories(
    $applicationIDs: [String!]!
    $period: String!
    $isReviewed: Boolean
    $shouldGroupByUserSelectedCategories: Boolean
    $appVersion: [String!]
    $source: [String!]
    $surveyID: [String!]
    $activatedExperimentUuids: [String!]
    $experimentUuids: [String!]
    $variantUuids: [String!]
    $isMiddleLevelCategory: Boolean
    $middleCategories: [String!]
  ) {
    getFeedbackOverviewCategories(
      applicationIDs: $applicationIDs
      period: $period
      isReviewed: $isReviewed
      shouldGroupByUserSelectedCategories: $shouldGroupByUserSelectedCategories
      appVersion: $appVersion
      source: $source
      surveyID: $surveyID
      activatedExperimentUuids: $activatedExperimentUuids
      experimentUuids: $experimentUuids
      variantUuids: $variantUuids
      isMiddleLevelCategory: $isMiddleLevelCategory
      middleCategories: $middleCategories
    ) {
      name
      avgCSAT
      avgCES
      avgNPS
      avgSR
      avgAWSPositive
      avgAWSNegative
      avgAWSMixed
      avgAWSNeutral
      avgStarRating
      avgStarRatingExternal
      feedbackCount
      nullVerbatimsCount
      categoryID
    }
  }
`

export const GET_FEEDBACK_OVERVIEW_SUBCATEGORIES = gql`
  query GetFeedbackOverviewSubcategories(
    $applicationIDs: [String!]!
    $period: String!
    $isReviewed: Boolean
    $appVersion: [String!]
    $category: String!
    $categoryID: String!
    $source: [String!]
    $surveyID: [String!]
    $activatedExperimentUuids: [String!]
    $experimentUuids: [String!]
    $variantUuids: [String!]
    $middleCategories: [String!]
  ) {
    getFeedbackOverviewSubcategories(
      applicationIDs: $applicationIDs
      period: $period
      isReviewed: $isReviewed
      appVersion: $appVersion
      category: $category
      categoryID: $categoryID
      source: $source
      surveyID: $surveyID
      activatedExperimentUuids: $activatedExperimentUuids
      experimentUuids: $experimentUuids
      variantUuids: $variantUuids
      middleCategories: $middleCategories
    ) {
      name
      subcategoryData {
        name
        avgCSAT
        avgCES
        avgNPS
        avgSR
        avgAWSPositive
        avgAWSNegative
        avgAWSMixed
        avgAWSNeutral
        avgStarRating
        avgStarRatingExternal
        feedbackCount
        nullVerbatimsCount
      }
    }
  }
`
export const GET_BUCKETED_FEEDBACK_TRENDS_BY_TIME = gql`
  query GetBucketedFeedbackTrendsByTime(
    $applicationIDs: [String!]!
    $period: String!
    $category: [String!]
    $subcategory: [String!]
    $manualCategory: [String!]
    $isReviewed: Boolean
    $appVersion: [String!]
    $isUserSelectedCategory: Boolean
    $source: [String!]
    $surveyID: [String!]
    $activatedExperimentUuids: [String!]
    $experimentUuids: [String!]
    $variantUuids: [String!]
  ) {
    getBucketedFeedbackTrendsByTime(
      applicationIDs: $applicationIDs
      period: $period
      category: $category
      subcategory: $subcategory
      manualCategory: $manualCategory
      isReviewed: $isReviewed
      appVersion: $appVersion
      isUserSelectedCategory: $isUserSelectedCategory
      source: $source
      surveyID: $surveyID
      activatedExperimentUuids: $activatedExperimentUuids
      experimentUuids: $experimentUuids
      variantUuids: $variantUuids
    ) {
      date
      avgCSAT
      avgCES
      avgNPS
      avgStarRating
      avgStarRatingExternal
      avgAWSPositive
      avgAWSNegative
      avgAWSMixed
      avgAWSNeutral
      avgSR
      feedbackCount
    }
  }
`

export const GET_CATEGORIES = gql`
  query GetCategories($applicationIDs: [String!]!) {
    getCategories(applicationIDs: $applicationIDs) {
      _id
      applicationIDs
      category
      parent
      subcategory
      description
      isArchived
    }
  }
`

export const GET_MANUAL_CATEGORIES = gql`
  query GetManualCategories($applicationIDs: [String!]!) {
    getManualCategories(applicationIDs: $applicationIDs) {
      _id
      applicationIDs
      category
      manualCategory
      description
      isArchived
    }
  }
`

export const GET_ENJOYMENT_RATINGS = gql`
  query GetEnjoymentRatings($startDate: Float!, $endDate: Float!, $index: String) {
    getEnjoymentRatings(startDate: $startDate, endDate: $endDate, index: $index) {
      applicationID
      yes
      no
    }
  }
`

export const GET_SURVEY_IDS = gql`
  query GetSurveyIDs($applicationIDs: [String!]!) {
    getSurveyIDs(applicationIDs: $applicationIDs)
  }
`

export const GET_APP_VERSIONS = gql`
  query GetAppVersions($period: String!, $applicationIDs: [String!]!) {
    getAppVersions(period: $period, applicationIDs: $applicationIDs) {
      label
      value
    }
  }
`

export const GET_USER = gql`
  query GetUser($entityID: String!, $authID: String!) {
    getUser(entityID: $entityID, authID: $authID) {
      _id
      columnGroups {
        _id
        userID
        name
        columns {
          name
          label
          orderNumber
        }
      }
    }
  }
`

export const GET_USER_SELECTED_CATEGORIES = gql`
  query GetUserSelectedCategories($applicationIDs: [String!]!, $period: String!) {
    getUserSelectedCategories(applicationIDs: $applicationIDs, period: $period)
  }
`

export const GET_VERBATIM_LANGUAGES = gql`
  query GetVerbatimLanguages($applicationIDs: [String!]!) {
    getVerbatimLanguages(applicationIDs: $applicationIDs)
  }
`

export const GET_BILLER_TYPES = gql`
  query GetBillerTypes($applicationIDs: [String!]!) {
    getBillerTypes(applicationIDs: $applicationIDs)
  }
`

export const GET_PAGE_NAMES = gql`
  query GetPageNames($applicationIDs: [String!]!) {
    getPageNames(applicationIDs: $applicationIDs)
  }
`

export const GET_USERS = gql`
  query GetUsers($authID: String!, $email: String!) {
    getUsers(authID: $authID, email: $email) {
      _id
      authID
      firstName
      lastName
      email
      role
      isArchived
      permissions {
        _id
        authID
        entityID
        read
        write
        isArchived
      }
    }
  }
`

export const GET_USER_PERMISSION_ONE = gql`
  query GetUserPermissionOne($authID: String!, $entityID: String!, $email: String!) {
    getUserPermissionOne(authID: $authID, entityID: $entityID, email: $email) {
      _id
      authID
      entityID
      read
      write
      isArchived
      columnGroupID
      user {
        _id
        authID
        firstName
        lastName
        email
        role
        isArchived
      }
    }
  }
`

export const GET_CSV_EXPORT_RECORD = gql`
  query GetCSVExportRecord($_id: String!) {
    getCSVExportRecord(_id: $_id) {
      status
      url
      fileName
      errorMessage
    }
  }
`

export const GET_ALL_FIELD_VALUES = gql`
  query GetFieldValues($applicationIDs: [String!]!, $fieldsForOptions: [String!]!) {
    getFieldValues(applicationIDs: $applicationIDs, fieldsForOptions: $fieldsForOptions) {
      source
      footprint
      browser
      os
      deviceManufacturer
      deviceType
      deviceModel
      deviceFormFactor
      loggedInStatus
      pageName
      accountType
      billerType
      autoPayStatus
      userType
      internetService
      secureConnectService
      tvService
      voiceService
      wifiService
      mobileService
      appVersion
      surveyID
    }
  }
`

export const GET_CATEGORY_GROUPS = gql`
  query GetCategoryGroups($applicationIDs: [String!]!) {
    getCategoryGroups(applicationIDs: $applicationIDs) {
      _id
      categoryGroupName
      categoryIds
      categoryOptions {
        label
        value
      }
    }
  }
`

export const GET_TRAINING_DATA = gql`
  query GetTrainingData(
    $isMockTrainingData: Boolean
    $categoryIDs: [String!]
    $applicationIDs: [String!]!
    $limit: Float
    $page: Float
    $search: String
  ) {
    getTrainingData(
      isMockTrainingData: $isMockTrainingData
      categoryIDs: $categoryIDs
      applicationIDs: $applicationIDs
      limit: $limit
      page: $page
      search: $search
    ) {
      id
      createdAt
      updatedAt
      categories {
        category
        parent
      }
      platform {
        name
      }
      value
      isMockTrainingData
      feedback {
        _id
        timestamp
        appID
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_TRAINING_DATA_COUNT = gql`
  query GetTrainingDataCount(
    $isMockTrainingData: Boolean
    $categoryIDs: [String!]
    $applicationIDs: [String!]!
    $search: String
  ) {
    count: getTrainingDataCount(
      isMockTrainingData: $isMockTrainingData
      categoryIDs: $categoryIDs
      applicationIDs: $applicationIDs
      search: $search
    )
  }
`
export const GET_TRAINING_DATA_METRICS = gql`
  query ($applicationIDs: [String!]!) {
    metrics: getTrainingDataMetrics(applicationIDs: $applicationIDs) {
      category {
        _id
        category
      }
      count
      isBeingTrained
    }
  }
`

export const GET_TRAINING_MODEL_METADATA_METRICS = gql`
  query GetAllJobInfo($applicationIDs: [String!]!) {
    metaData: getAllJobInfo(applicationIDs: $applicationIDs) {
      versionName
      trainDate
      accuracy
      f1Score
      platform
      inUse
      numberOfLabels
    }
  }
`

export const GET_PLATFORM_BY_APPLICATION_IDS = gql`
  query GetPlatformByApplicationIDs($applicationIDs: [String!]!) {
    getPlatformByApplicationIDs(applicationIDs: $applicationIDs) {
      _id
      name
      description
      applicationIDs
      mlAnalysisField
      createdAt
      updatedAt
    }
  }
`

export const GET_FILE_UPLOADS = gql`
  query getFileUploads($_ids: [String!]!) {
    getFileUploads(_ids: $_ids){
      _id
      files
    }
  }
`
export const GET_FILE_UPLOAD = gql`
  query getFileUpload($_id: String!) {
    getFileUpload(_id: $_id) {
      _id
      files
    }
  }
`