import React from 'react'
import { Link, useLocation } from '@reach/router'
import Entity from 'components/shared/Entity'
import useKrypto from 'screens/tools/krypto/general/useKrypto'
import useCPEAccess from 'hooks/useCPEAccess'
import useCSTAccess from 'hooks/useCSTAccess'

export default function ToolList({
  handleClose,
}: {
  handleClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}) {
  const location = useLocation()
  const { hasGeneralKryptoAccess } = useKrypto()
  const { hasCPEWriteAccess, hasCPEReadAccess } = useCPEAccess()
  const { hasCSTReadAccess } = useCSTAccess()
  const tools = [
    {
      name: 'Sequence',
      id: 'sequence',
      customURLFilters: `${location.search}`,
    },
    {
      name: 'Volt',
      id: 'volt/test-cases',
      description: 'Testing tool',
    },
    { name: 'Voice of Customer Summary', id: 'voc-summary' },
    { name: 'Tableau Reports', id: 'tableau/reports' },
    {
      name: 'Photon',
      id: 'Photon',
      link: 'https://photon.pi.spectrumtoolbox.com/',
      isExternal: true,
    },
    {
      name: 'Distillery',
      id: 'Distillery',
      link: 'https://distillery-next.spectrumtoolbox.com/',
      isExternal: true,
    },
    {
      name: 'Quantum Dashboard',
      id: 'Quantum Dashboard',
      link: 'https://quantum-dashboard.pi.spectrumtoolbox.com/',
      isExternal: true,
    },
    {
      name: 'Pivot',
      id: 'Pivot',
      link: 'https://pi-dasp.pi.spectrumtoolbox.com/pivot/home',
      isExternal: true,
    },
    {
      name: 'Tech Mobile Work Orders',
      id: 'Tech Mobile Work Orders',
      link: 'https://navigator.prd-aws.charter.net/tools/WorkOrderJourney',
      isExternal: true,
    },
    {
      name: 'Positron',
      id: 'Positron',
      link: 'https://positron.spectruminternal.com/',
      isExternal: true,
    },
    ...(hasCSTReadAccess
      ? [
          {
            name: 'Charter Survey Tool',
            id: 'cst',
            isBeta: true,
          },
        ]
      : []),
    ...(hasCPEWriteAccess || hasCPEReadAccess
      ? [
          {
            name: 'CPE Swap Tool',
            id: 'cpe',
            isBeta: true,
            isProduct: true,
          },
        ]
      : []),
    ...(hasGeneralKryptoAccess ? [{ name: 'Krypto', id: 'krypto' }] : []),
  ]

  const toolRows = tools.map(
    ({
      name,
      description,
      id,
      link,
      isExternal,
      customURLFilters,
      isBeta,
      isProduct,
    }: {
      name: string
      description?: string
      id: string
      customURLFilters?: string
      link?: string
      isExternal?: boolean
      isBeta?: boolean
      isProduct?: boolean
    }) => {
      if (isExternal) {
        return (
          <a href={link} target="blank" data-pho={`external${name}link`}>
            <Entity title={name} description={description} isExternal isBeta={isBeta} />
          </a>
        )
      }

      if (isProduct) {
        return (
          <Link onClick={handleClose} to={`/datalens/${id}`}>
            <Entity title={name} description={description} data-pho="tool" isBeta={isBeta} />
          </Link>
        )
      }

      const to = customURLFilters ? `/tools/${id}${customURLFilters}` : `/tools/${id}`

      return (
        <Link onClick={handleClose} to={to}>
          <Entity title={name} description={description} data-pho="tool" isBeta={isBeta} />
        </Link>
      )
    }
  )

  return <div>{toolRows}</div>
}
